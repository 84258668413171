import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BrandHeader from "../../components/brand-headers/lehighCustomfitBrandHeader"
import BrandBody from "../../components/brand-bodies/lehighCustomfitBrandBody"

const LehighBootsPage = ({ pageContext, location }) => {
  const pageTitle = "Lehigh Custom Fit"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />
        <BrandHeader />
        <BrandBody />
      </Layout>
      {/* description for search, hidden from page */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        Celebrating over 100 years of providing innovative solutions, Lehigh
        CustomFit is the industry's leader in managed PPE Programs for Safety
        Footwear and Foot &amp; Body Wellness. Our custom online solution
        provides companies greater control to track compliance, control spend,
        control what employees wear &amp; easily manage their eligibility. With
        a foundation of wellness, CustomFit has introduced industry-first
        assessment tools such as a 3D foot scanner to alleviate and prevent
        employee physical health issues that result from all-day standing and
        repetitive working conditions; therefore reducing a company's worker's
        comp claims, premiums, lost-time productivity, &amp; overall cost while
        improving their safety work culture.
      </p>
    </>
  )
}

export default LehighBootsPage

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Lehigh CustomFit</title>
    <meta
      name="description"
      content="Celebrating over 100 years of providing innovative solutions, Lehigh CustomFit is the industry's leader in managed PPE Programs for Safety Footwear and Foot &amp; Body Wellness. Our custom online solution provides companies greater control to track compliance, control spend, control what employees wear &amp; easily manage their eligibility. With a foundation of wellness, CustomFit has introduced industry-first assessment tools such as a 3D foot scanner to alleviate and prevent employee physical health issues that result from all-day standing and repetitive working conditions; therefore reducing a company's worker's comp claims, premiums, lost-time productivity, &amp; overall cost while improving their safety work culture."
    />
  </>
)
