import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"
import { Link } from "gatsby"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About Lehigh CustomFit</h2>
          <p>Celebrating over 100 years of providing innovative solutions, Lehigh CustomFit is the industry's leader in managed PPE Programs for Safety Footwear and Foot &amp; Body Wellness. Our custom online solution provides companies greater control to track compliance, control spend, control what employees wear &amp; easily manage their eligibility. With a foundation of wellness, CustomFit has introduced industry-first assessment tools such as a 3D foot scanner to alleviate and prevent employee physical health issues that result from all-day standing and repetitive working conditions; therefore reducing a company's worker's comp claims, premiums, lost-time productivity, &amp; overall cost while improving their safety work culture.</p>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-headers/rocky/factory-sepia.jpg"
            className={brandBodyStyles.halfImage}
            alt="Rocky factory 1933"
            imgStyle={{width: `100%`, height: `100%`}}
            style={{width: `100%`, height: `100%`}}
          />
        </div>
      </div>
      {/* <div className={brandBodyStyles.highlightSection}>
        <div className={brandBodyStyles.imageGrid}>
            <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-outdoor-square.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 10px 10px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-work-square.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 20px 10px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-western-square.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 10px 20px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-psd-commil-square.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 20px 20px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
          </div>
          <div className={brandBodyStyles.highlights}>
            <h3>Highlights</h3>
            <h4>Foundation of Wellness</h4>
            <p>Feet are the structural foundation of the body and essential to overall health &amp; wellness. Everyday working conditions can cause debilitating ailments to employees. A Lehigh CustomFit Wellness program provides assessment tools such as a 3D Foot Scanner, condition-built footwear, Aetrex custom orthotics &amp; OS1st medical-grade compression socks to correct and protect.</p>
            <h4>The Right Fit</h4>
            <p>Incorporating the innovative digital &amp; data recommendation of both Aetrex FitGenius&trade; and True Fit&reg;, CustomFit can provide each individual company employee the greatest level of footwear fit accuracy on the market. This results in fewer physical issues and lower return hassle.</p>
            <h4>Customized Website</h4>
            <p>Company employees order through a dedicated and password-protected website that displays only products chosen to show with set subsidy or other payment options, 24/7 access from any device, free shipping and returns and a 60-day guarantee. Eliminates voucher spreadsheets and distribution hassle.</p>
            <h4>Command Center Dashboard</h4>
            <p>Secure and dedicated online dashboard provides program data at your fingertips. View real-time eligibility usage to track usage and prove compliance. Eliminate abuse with eligibility auto reset and by adding or deleting employees. View style and sales metrics to report budget and save.</p>
          </div>
      </div> */}
      <div className={brandBodyStyles.strategy}>
        <h4>Lehigh CustomFit Strategy</h4>
        <p>For 100 years, Lehigh has been an innovative supplier of safety footwear. We were at the forefront of steel toe development and pioneered the on-site shoe truck. After years of providing this leading-edge service, we keyed in on its painful limitations of supply, selection and service times and progressively transitioned into an online solution we named CustomFit.  Combining our experience, expertise and forward-thinking initiatives we will continue to lead the industry in excellence, innovation, quality service and safety.</p>
        <div className={brandBodyStyles.strategyButtons}>
          <Link to="/customer-resources/for-our-consumers/" title="Customer Resources" className="CTAalt">
            Customer Resources
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BrandBody
